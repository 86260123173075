<template>
  <div>
    <input
      id="file-input"
      ref="fileUpload"
      type="file"
      name="name"
      style="display: none;"
      @change="onFileDialogUpload"
    />

    <div v-if="logoPath" class="control logo-control has-margin-bottom-50">
      <div class="input has-padding-150 is-flex" style="height:auto;">
        <loading v-if="!base64Logo" />
        <picture v-else :title="'Logo'" :style="{ margin: `auto` }">
          <img class="is-block" :style="{ height: `80px` }" :src="base64Logo" />
        </picture>
        <a @click="confirmDeleteLogo">
          <b-icon icon="trash" size="is-small" />
        </a>
      </div>
    </div>

    <div>
      <a
        class="button is-outlined"
        :class="{ 'is-loading': progress }"
        @click="$refs.fileUpload.click()"
        >{{ logoPath ? "Change" : "Upload" }} logo</a
      >
    </div>

    <div class="is-clearfix" />
  </div>
</template>

<script>
import { ref as storageRef, deleteObject } from "firebase/storage";
import { deleteField, doc, updateDoc } from "@firebase/firestore";
export default {
  name: "ResellerLogoUpload",
  props: {
    resellerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      progress: false,
      deleting: false
    };
  },
  computed: {
    reseller() {
      return this.$store.getters["reseller/reseller"](this.resellerId);
    },
    logoPath() {
      return this.$_.get(this.reseller, "logoPath", null);
    },
    base64Logo() {
      return this.$_.get(this.reseller, "logo", null)
        ? `data:${this.reseller.logo.contentType};base64,${this.reseller.logo.base64}`
        : null;
    }
  },
  methods: {
    confirmDeleteLogo() {
      const modal = this.$confirm.open({
        parent: this,
        canCancel: ["escape", "outside"],
        props: {
          autoClose: false,
          action: "Delete logo",
          message: "Are you sure you want to delete your brand logo?"
        },
        events: {
          confirmed: () => {
            this.deleteLogo().then(() => modal.close());
          }
        }
      });
    },
    deleteLogo() {
      this.deleting = true;
      const ref = doc(this.$firestore, `resellers/${this.resellerId}`);
      return updateDoc(ref, {
        logo: deleteField(),
        logoPath: deleteField()
      })
        .then(() => {
          const fileRef = storageRef(
            this.$storage(
              `${process.env.VUE_APP_FIREBASE_PROJECT_ID}-reseller`
            ),
            `${this.resellerId}/logo`
          );
          return deleteObject(fileRef);
        })
        .then(() => {
          this.deleting = false;
          this.$toast.open({
            message: "Logo deleted",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.deleting = false;
          this.$toast.open({
            message: "Error deleting logo",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    },
    onFileDialogUpload(e) {
      this.progress = true;
      this.$_.each(e.target.files, file => {
        this.$emit("fileselected", file);
        const fileRef = storageRef(
          this.$storage(`${process.env.VUE_APP_FIREBASE_PROJECT_ID}-reseller`),
          `${this.resellerId}/logo`
        );
        this.$store
          .dispatch(
            "file/uploadFile", // Upload file
            {
              fileRef,
              file,
              metadata: {
                contentType: file.type,
                customMetadata: {
                  resellerId: this.resellerId
                }
              }
            }
          )
          .then(({ uploadTask }) => {
            uploadTask.on(
              "state_changed",
              () => {},
              error => {
                this.progress = false;
                console.error(error.message);
              },
              () => {
                this.progress = false;
                updateDoc(doc(this.$firestore, "resellers", this.resellerId), {
                  logoPath: uploadTask.snapshot.metadata.fullPath
                });
                this.$toast.open({
                  message: "Logo updated",
                  position: "is-bottom",
                  queue: false
                });
              }
            );
          });
      });
      e.target.value = null;
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

.logo-control {
  & > div > a {
    position: absolute;
    top: 0;
    right: 0;
    color: $danger;
    padding: 0.5em 0.75em;
    &:hover {
      transform: scale(1.15);
    }
  }
}

picture.logo {
  display: block;
  position: relative;
  border-radius: 0.3em;
  overflow: hidden;
  width: 100%;
  //padding-top: 62.5%; /* 8:5 aspect Ratio */

  span.screenshot-fallback {
    position: absolute;
    width: 80px;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    background: url(/assets/images/interface/camera.svg) center no-repeat;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}
</style>
